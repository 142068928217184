import React, {useState, useEffect} from 'react'
import {db} from '../config/firebase'
import { collection, getDocs } from 'firebase/firestore';
import { FaUsers } from "react-icons/fa6";
import { BsWatch } from "react-icons/bs";
import LoadingScreen from './LoadingScreen';

function HomeScreen () {


  const [loading, setLoading] = useState(true)
  const [showContent, setShowContent] = useState(false)

  const [userCount, setUserCount] = useState(0);
  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const usersCollection = collection(db, 'Users'); // Replace 'Users' with your actual collection name
        const snapshot = await getDocs(usersCollection);
        setUserCount(snapshot.size); // Get the number of documents in the collection
      } catch (error) {
        console.error("Error fetching user count: ", error);
      }
    };

    fetchUserCount();
  }, []);
    
  const [productCount, setProductCount] = useState(0);
  useEffect(() => {
    const fetchProductCount = async () => {
      try {
        const productsCollection = collection(db, 'Products'); // Replace 'Users' with your actual collection name
        const snapshot = await getDocs(productsCollection);
        setProductCount(snapshot.size); // Get the number of documents in the collection
      } catch (error) {
        console.error("Error fetching user count: ", error);
      } finally {
        setLoading(false)
        setShowContent(true)
      }
    };

    fetchProductCount();
  }, []);
    

        return (
            <>
            <LoadingScreen isLoading={loading}  />
            <div style={{ padding: "16px", transition: "opacity 0.1s ease-in-out", opacity: showContent ? 1 : 0,display:"flex"  }}>
                <div style={{backgroundColor:"#F7F7F7", padding:"8px 16px", width:"10%", borderRadius:"8px", display:"flex", flexDirection:"column"}}>
                <FaUsers size={27} />

                    <p style={{alignSelf:"end", fontSize:20, marginBottom:"0px"}}>{userCount}</p>
                </div>


                <div style={{backgroundColor:"#F7F7F7", padding:"8px 16px", width:"10%", borderRadius:"8px", display:"flex", flexDirection:"column", marginRight:"16px"}}>
                <BsWatch size={27} />

                    <p style={{alignSelf:"end", fontSize:20, marginBottom:"0px"}}>{productCount}</p>
                </div>
            </div>
            </>
        );
}
 
export default HomeScreen;