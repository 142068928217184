import React, {useEffect, useRef} from 'react'
import { useState } from 'react';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, onSnapshot, query, where,getDoc, updateDoc, limit,getCountFromServer ,doc, setDoc, Timestamp, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref as storagePath, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { db, auth, storage } from '../config/firebase'
import LoadingScreen from './LoadingScreen';
import { getFunctions, httpsCallable } from "firebase/functions";
import Lottie from 'lottie-react';
import SignedInAnim from '../assets/animations/SignedInAnim.json';
import { toast } from 'react-toastify'; // Import toast



function SignInScreen ({prompt}) {
    const countryCode = "+966"
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [OTP, setOTP] = useState("");
    const [hasSelectedProfilePicture, setHasSelectedProfilePicture] = useState(false);
    const [profilePictureName, setProfilePictureName] = useState("");
    const [otpSent , setOtpSent] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [userExists, setUserExists] = useState(false);

    const [sendOTPLoading, setSendOTPLoading] = useState(false)
    const [timer, setTimer] = useState(0);

    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight
    });

    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };
  
      window.addEventListener('resize', handleResize);
  
      // Initial call to set the state with current window dimensions
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const otpRef = useRef();

    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
    };
    

    useEffect(() => {
      let interval = null;
      if (timer > 0) {
        interval = setInterval(() => {
          setTimer(timer - 1);
        }, 1000);
      } else if (timer === 0) {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }, [timer]);



    useEffect(()=>{
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }, [])

    useEffect(() => {
      auth.onAuthStateChanged(async function (user) {
        if(!user){
        setLoading(false);
          }
      });
    }, [auth.currentUser]);

    useEffect(() => {
      if (OTP.length === 6) {
        setLoading(true)
        verifyOTP();
      }
    }, [OTP]);

    useEffect(() => {
      if (otpRef && otpRef.current) {
        otpRef.current.scrollIntoView({ behavior: 'smooth' });
        otpRef.current.focus();
      }
    }, [otpRef]);
    


    const phoneNumberRef = useRef();

    const generateRecaptcha = () => {
      if (window.recaptchaVerifier) {
        // RecaptchaVerifier already exists, no need to recreate
        return;
      }
  
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      });
    };

    const requestOTP = async () => {
      setErrorMsg("");
      
      // Validate phone number input
      if (isNaN(parseInt(phoneNumber))) {
        setErrorMsg("يرجى ادخال قيمة صحيحة. يسمح بالأرقام الانجليزية فقط 0-9");
        return;
      }
    
      // Check if the timer is active
      if (timer > 0) {
        setErrorMsg(`يرجى الانتظار ${formatTime(timer)} قبل طلب رمز جديد`);
        return;
      }
    
      // Format phone number if it starts with "05"
      let formattedPhoneNumber = phoneNumber;
      if (phoneNumber.startsWith("05")) {
        formattedPhoneNumber = phoneNumber.substring(1);
      }
    
      try {
        setSendOTPLoading(true);
        setTimer(120); // Set the timer to 120 seconds (2 minutes) after OTP request
    
        // Proceed with OTP request if phone number is valid
        if (formattedPhoneNumber.length === 9 && !isNaN(parseInt(formattedPhoneNumber))) {
          // Query Firestore to check if the user exists
          const userQuery = query(collection(db, 'Users'), where('phoneNumber', '==', `${countryCode}${formattedPhoneNumber}`));
          const querySnapshot = await getDocs(userQuery);
    
          setOtpSent(true);
          generateRecaptcha();
    
          const appVerifier = window.recaptchaVerifier;
          signInWithPhoneNumber(auth, `${countryCode}${formattedPhoneNumber}`, appVerifier)
            .then((confirmationResult) => {
              window.confirmationResult = confirmationResult;
            })
            .catch((error) => {
              setErrorMsg('حدث خطأ أثناء إرسال رمز التحقق', error);
              console.log(error);
            });
    
          // Set user existence based on Firestore query result
          if (!querySnapshot.empty) {
            if(querySnapshot.docs[0].data().type === "Admin")
            setUserExists(true);
          } else {
            setUserExists(false);
          }
        } else {
          setErrorMsg('الرجاء إدخال رقم هاتف صحيح');
        }
      } catch (error) {
        console.error('Error requesting OTP:', error);
        setErrorMsg('حدث خطأ أثناء إرسال الرمز: ' + error);
      }
      
      setSendOTPLoading(false);
    };
    

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'instant' // Scroll behavior set to instant for instant scrolling
      });
    };
  
    const[verifyOTPLoading, setVerifyOTPLoading] = useState(false)

    const verifyOTP = async () => {
      try {
        scrollToTop()
        const confirmationResult = window.confirmationResult;

        confirmationResult
          .confirm(OTP)
          .then(async (result) => {
            // Handle successful OTP verification
            if (!userExists) {
                toast.error('ليست لديك الصلاحية للدخول');
                auth.signOut();
                return;
            } else{
                navigate("/")
            }
            
          })
          .catch((error) => {
            // Handle OTP verification error
            console.error('Error confirming OTP:', error);
            setErrorMsg('خطأ في تأكيد الرمز');
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        console.error('Error during OTP verification:', error);
        setErrorMsg('حدث خطأ أثناء تأكيد الرمز');
      } finally {
        setVerifyOTPLoading(false)
      }
    };

const handleOTPChange = (e) => {
  const otpValue = e.target.value;
  if (otpValue.length <= 6) {
    setOTP(otpValue); // Update OTP state but do not verify yet
  }
};


    if(isLoading){
      return <LoadingScreen/>
    }

        return (
          <>
                      { verifyOTPLoading && (
  <>
    <div style={{width:"100vw", height:"100vh", backgroundColor: 'rgba(0, 0, 0, 0.2)',  position:"fixed", zIndex:"1010", display:"flex", justifyContent:"center", alignItems:"center", top:0}}>
      <div style={{backgroundColor:"white",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", borderRadius:"12px"}}>
      <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              padding:"32px"
            }}
          >
                    <Oval
          height={"35px"}
          width={"35px"}
          color={"#00C8B8"}
          wrapperClass=""
          visible={true}
          wrapperStyle={{ display: "block" }}
          ariaLabel='oval-loading'
          secondaryColor="#ccc"
          strokeWidth={2}
          strokeWidthSecondary={1}
        />
          </div>

      </div>
    </div>
  </>
)}
          { !auth.currentUser ? (
            <div style={{
              backgroundColor:"#f0f0f0",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              display: "flex"
          }}>
              <div style={{
                  backgroundColor : "white",
                  width: window.innerWidth > window.innerHeight ? "50%" : window.innerWidth < 600 ? "90%" : "60%",
                  borderRadius: "20px",
                  marginTop: "-20%",
                  boxShadow: "0px 1px 0px 0px #ccc",
                  padding: "32px 16px"
              }}>
                
                  {!prompt ? <p>الرجاء التسجيل او تسجيل الدخول لرؤية هذه الصفحة </p> : <p>{prompt}</p>}

                  <div style={{
                  display: "flex",
                  margin: "8px",
                  marginTop:"16px",
                  }}>
                  <div style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                  }}>

                  <p style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "4px"
                      }}>+966</p>
                  </div>
                      <input type='tel' ref={phoneNumberRef} onChange={(value)=>{
                        setPhoneNumber(value.target.value)}} style={{display: "block", width: "100%",borderRadius: "4px", border: "none", height: "40px", direction: "rtl", paddingRight: "8px", border: "1px solid #f0f0f0", backgroundColor:"white"}} placeholder='رقم الجوال'/>
                  </div>
                      <p style={{color:"red", display: errorMsg != "" ? "block" : "none", fontSize:"14px"}}>{errorMsg}</p>
                      <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"16px"}}>
                      
                      <button id='my-button' 
              disabled={sendOTPLoading || timer > 0}
              style={{fontSize: "14px", width: "60%"}} onClick={requestOTP}>
{   
   sendOTPLoading ?    
   <div style={{
  alignItems: 'center',
  width: "100%",
  height: "100%",
  justifyContent: 'center',
  display:"flex"
    }}>
      <Oval
            height={"20px"}
            width={"20px"}
            color={"white"}
            visible={true}
            wrapperStyle={{display: "block"}}
            ariaLabel='oval-loading'
            secondaryColor="#ccc"
            strokeWidth={1}
            strokeWidthSecondary={1}
            />
            </div>
             : <div style={{display:"flex", justifyContent:"space-around", alignItems:"center", padding:"0px 16px"}}>
              {timer > 0 && <p style={{marginBottom:"0px", color:"white", marginRight:"8px"}}>{formatTime(timer)}</p>}
              <p style={{whiteSpace:"nowrap", marginBottom:"0px"}}>
              إرسال رمز التحقق
              </p>

             </div>
            }
                </button>
                      </div>
                  <div
                  style={{
                  justifyContent: "center",
                  display: otpSent ? "flex" : "none",
                  flexDirection: "column",
                  alignItems: "center",
                  }}
                  >
                      <input
                      type='text'
                      inputMode='numeric'
                      pattern='[0-9]*'
                      autoComplete='one-time-code'
                      autoFocus // Set autoFocus based on the OTP value
                      style={{width: "40%",borderRadius: "8px", border: "none", textAlign:"center" , height: "40px", marginTop: "32px" , direction: "rtl", paddingRight: "4px", paddingLeft:"4px" , border: "1px solid #f0f0f0", fontSize:"14px"}}
                      onChange={handleOTPChange}
                      value={OTP}
                      placeholder='ادخل الرمز'
                      ref={otpRef}
                      />
                      <p style={{color: "gray", marginTop:"8px", fontSize:"14px"}}>قد يستغرق وصول الرمز دقيقة واحدة</p>
                      {/* <button id='my-button' style={{width:"50%"}} onClick={verifyOTP} >

                      {   
   sendOTPLoading ?    
   <div style={{
  alignItems: 'center',
  width: "100%",
  height: "100%",
  justifyContent: 'center',
  display:"flex"
    }}>
      <Oval
            height={"20px"}
            width={"20px"}
            color={"white"}
            visible={true}
            wrapperStyle={{display: "block"}}
            ariaLabel='oval-loading'
            secondaryColor="#ccc"
            strokeWidth={1}
            strokeWidthSecondary={1}
            />
            </div>
             : <p style={{whiteSpace:"nowrap", marginBottom:"0px"}}> </p>
            }
                      </button> */}
                  </div>
                  {/* <p style={{marginBottom:"0px", marginTop:"22px", fontSize: "14px"}}>إذا كنت مستخدمًا جديدًا وأنشأت حسابًا، فإنك توافق على <Link to="/user_policy">سياسة الاستخدام و الخصوصية</Link></p> */}
              </div>
              <div style={{display: "none", visibility:"hidden" , justifyContent: "center", alignItems: "center", marginBottom: "50px"}} id='recaptcha-container'></div>
          </div>
          ) : (
            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh"  }}>
            <Lottie
              style={{ width: "30%", }}
              animationData={SignedInAnim}
              loop={true}
            />
            <p style={{ marginTop: "16px", color: "#00A49B", fontWeight:500 }}>أنت مسجل دخول بالفعل</p>
          </div>
          )
        }
          </>
        );
}
 
export default SignInScreen;