import React from 'react'
import { RxHamburgerMenu } from "react-icons/rx";
import Logo from '../assets/logo.png'
function Navbar ({openDrawer}) {
        return (
            <div
            style={{
                width: "100%",
                height: "55px",
                position: "fixed",
                backgroundColor: "white",
                display: "flex",
                left:0,
                right:0,
                boxShadow: window.innerWidth > window.innerHeight ? "0 0px 2px rgba(0, 0, 0, 0.2)" : "none",
                zIndex: 1,
                alignItems:"center",
                padding:"0px 16px",
                boxSizing:"border-box",
                overflow:"hidden"
            }}
            >
                {/* {
                 window.innerHeight > window.innerWidth && 
                <RxHamburgerMenu onClick={openDrawer} size={24} style={{minHeight:"24px", minWidth:"24px", marginRight:"16px"}} />
                 } */}
     <div style={{display:"flex", alignItems:"center", backgroundColor:"white"}}>
        <img src={Logo} alt="Logo" style={{ height: "60px" }} />
        <p style={{ fontSize: "22px", marginBottom: "8px", color: "#01635c", marginRight: "8px", fontWeight:500 }}>بيع</p>
      </div>
      {/* {window.innerHeight > window.innerWidth && <IoCloseOutline onClick={closeDrawer} color='#F00057' size={28} style={{minWidth:"28px", minHeight:"28px", marginLeft:"0px", marginTop:"16px"}} />} */}
                {/* <input type='search' className='search-bar' placeholder='ابحث هنا' /> */}
            </div>
        );
}
 
export default Navbar;