import React, {useEffect, useRef, useState} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LuClipboardList } from "react-icons/lu";
import { toast } from 'react-toastify'; // Import toast
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import LoadingScreen from './LoadingScreen';
import { Divider } from '@mui/material';
import { AiFillStar } from "react-icons/ai";
import { FaStore } from "react-icons/fa";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { GoPaperclip } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { auth, storage } from '../config/firebase'
import { getFunctions, httpsCallable } from 'firebase/functions';
import SuccessAnim from '../components/SuccessAnim';
import { Oval } from 'react-loader-spinner';
import { FaRegEye } from "react-icons/fa";
import * as XLSX from 'xlsx';
import excelFile from '../assets/excel/ServedCities.xlsx'; // Import the Excel file

function UserDetails() {
  const location = useLocation();
  const {uid} = useParams();

  const [user, setUser] = useState(null)
  const [orders, setOrders] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showSuccessAnim, setShowSuccessAnim] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleAnimationComplete = () => {
    setShowSuccessAnim(false); // Reset button state after animation completes
    // navigate(-1)
  };

  const [fileName, setFileName] = useState(null)
  const [transferPDF, setTransferPDF] = useState(null)

  const [confirmTransferLoading, setConfirmTransferLoading] = useState(false)

  const transferPDFRef = useRef();

  const [showContent, setShowContent] = useState(false); // State for content visibility
  const [myId, setMyId] = useState(null); // State for content visibility
  const [fullName, setFullName] = useState(user?.fullName || ""); // State for content visibility
  const [city, setUserCity] = useState(user?.city || ""); // State for content visibility


  const [addressCity, setAddressCity] = useState(user?.address.city || '');
  const [neighborhood, setNeighborhood] = useState(user?.address.neighborhood || '');
  const [street, setStreet] = useState(user?.address.street || '');
  const [notes, setNotes] = useState(user?.address.notes || '');
  
  
  const [bank, setBank] = useState(user?.bank || '');
  
  const [isBusiness, setIsBusiness] = useState(user?.isBusiness || false);
  const [isVerified, setIsVerified] = useState(user?.isVerified || false);
  const [email, setEmail] = useState(user?.email || "");
  const [iban, setIban] = useState(user?.iban || "");
  const [businessCRurl, setBusinessCRurl] = useState(user?.businessCRurl || "");
  const [commission, setCommission] = useState(user?.commission || 7);
  
  const [businessCRnumber, setCRNumber] = useState(user?.businessCRnumber || "");
  const [businessVerificationNumber, setBusinessVerificationNumber] = useState(user?.businessCRurl || "");
  const [ibanLetterUrl, setIbanLetterUrl] = useState(user?.ibanLetterUrl || "");
  
  
  const [isBanned, setIsBanned] = useState(user?.isBanned || false);
  const [isBannedFromAddingProducts, setIsBannedFromAddingProducts] = useState(user?.isBannedFromAddingProducts || false);
  const [isBannedFromChatting, setIsBannedFromChatting] = useState(user?.isBannedFromChatting || false);
  const [isBannedFromCommenting, setIsBannedFromCommenting] = useState(user?.isBannedFromCommenting || false);
  const [isBannedFromReporting, setIsBannedFromReporting] = useState(user?.isBannedFromReporting || false);
  const [isAdmin, setIsAdmin] = useState(user?.isAdmin || false);

  const [cities, setCities] = useState([]);


// fetch cities
  useEffect(() => {
    const fetchCities = async () => {
      try {
        // Fetch the Excel file from the imported URL
        const response = await fetch(excelFile);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        // Convert the response to an ArrayBuffer
        const arrayBuffer = await response.arrayBuffer();
  
        // Parse the ArrayBuffer using XLSX
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
  
        const sheetName = workbook.SheetNames[0]; // Get the first sheet
        const sheet = workbook.Sheets[sheetName];
  
        // Read sheet data as an array of arrays
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
        // Assuming the first column is English and the second is Arabic
        const cityList = data.map(row => ({
          english: row[0], // English city name (value)
          arabic: row[1],  // Arabic city name (label)
        })).filter(city => city.english && city.arabic); // Ensure both columns exist
  
        setCities(cityList);
      } catch (error) {
        console.error('Error fetching or parsing the Excel file:', error);
      }
    };
  
    fetchCities();
  }, []);

  // fetch seller's data
  useEffect(() => {
    const fetchUserAndOrders = async () => {
      // Fetch user data
      const userDocRef = doc(db, 'Users', uid); // Reference to the user document

      try {
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          const userData = { uid: docSnapshot.id, ...docSnapshot.data() };
          setUser(userData);
          setFullName(userData.fullName)
          setUserCity(userData.city)
          setAddressCity(userData.address.city)
          setNeighborhood(userData.city.neighborhood)
          setStreet(userData.address.street)
          setNotes(userData.address.notes)
          setBank(userData.bank)
          setIsBusiness(userData.isBusiness)
          setIsVerified(userData.isVerified)
          setEmail(userData.email)
          setIban(userData.iban)
          setBusinessCRurl(userData.businessCRurl)
          setCommission(userData.commission)
          setCRNumber(userData.businessCRnumber)
          setBusinessVerificationNumber(userData.businessVerificationNumber)
          setIbanLetterUrl(userData.ibanCertificateURL)
          setIsBanned(userData.isBanned)
          setIsBannedFromAddingProducts(userData.isBannedFromAddingProducts)
          setIsBannedFromChatting(userData.isBannedFromChatting)
          setIsBannedFromCommenting(userData.isBannedFromCommenting)
          setIsAdmin(userData.isAdmin)
        } else {
          console.error('No such user!');
        }
      } catch (error) {
        console.error('Error fetching user or orders:', error);
      } finally {
          setTimeout(() => {
            setLoading(false);
        }, 300); // Adjust the delay as needed
        setTimeout(() => {
          setShowContent(true); // Trigger visibility change with a slight delay
        }, 300); // Adjust the delay as needed
      }
    };

    fetchUserAndOrders();
  }, [uid]); // Dependency array includes `uid` to refetch if it changes


    // fetch my id
    useEffect(() => {
        const fetchCurrentUserId = async () => {
          try {
            const currentUserRef = doc(db, 'Users', auth.currentUser.uid); // Assuming current user's document ID is stored here
            const currentUserSnap = await getDoc(currentUserRef);
    
            if (currentUserSnap.exists()) {
          setMyId(currentUserSnap.data().id)
            } else {
              console.error('User document not found');
            }
          } catch (error) {
            console.error('Error fetching current user:', error);
          }
        };
    
        fetchCurrentUserId();
      }, []);


  const handleFileChange = () => {
    if (transferPDFRef.current && transferPDFRef.current.files.length > 0) {
      setFileName(transferPDFRef.current.files[0].name);
      setTransferPDF(transferPDFRef.current.files[0])
    } else {
      setFileName("");
    }
  };

  

  function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Extract Base64 string from data URL
        resolve(reader.result.split(',')[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file); // Read the file as a data URL
    });
  }

  const downloadIbanLetter = () => {
    // Check if the ibanLetterUrl exists in the request object
    if (ibanLetterUrl) {
      // Trigger the file download by opening the URL in a new tab
      window.open(ibanLetterUrl, '_blank'); // Opens the file in a new tab
    } else {
      console.error("IBAN Letter URL not found in the provided request object.");
      alert("IBAN Letter not available for this request.");
    }
  };
  const downloadCR = () => {
    // Check if the ibanLetterUrl exists in the request object
    if (businessCRurl) {
      // Trigger the file download by opening the URL in a new tab
      window.open(businessCRurl, '_blank'); // Opens the file in a new tab
    } else {
      console.error("IBAN Letter URL not found in the provided request object.");
      alert("IBAN Letter not available for this request.");
    }
  };
  
  const [updateLoading, setUpdateLoading] = useState(false)

  const updateUser = async () => {
    const functions = getFunctions();
    const updateUserInfo = httpsCallable(functions, 'adminUpdateUserInfo');


    let userInfo = {
      fullName,
      address: {
        city: addressCity,
        neighborhood,
        street,
        notes
      },
      city,
      bank,
      email,
      iban,
      isBanned: isBanned === "true",
      isBannedFromAddingProducts: isBannedFromAddingProducts === "true",
      isBannedFromChatting: isBannedFromChatting === "true",
      isBannedFromCommenting: isBannedFromCommenting === "true",
      isBannedFromReporting: isBannedFromReporting === "true",
      isAdmin: isAdmin === "true",
      commission
    }


    try {
      setUpdateLoading(true)
      const result = await updateUserInfo({ uid, userInfo });
  
      if (result.data.success) {
        // console.log('User updated successfully:', result.data.message);
        setSuccessMessage("تم تحديث بيانات العميل")
        setShowSuccessAnim(true)
        // Handle success, e.g., update UI or state
      } else {
        console.error('Error updating user:', result.data.error);
        // Handle error, e.g., show error message
      }
    } catch (error) {
      console.error('Error calling updateUserInfo function:', error);
    } finally {
      setUpdateLoading(false)
    }
  };

  return (
    <>
      {showSuccessAnim && <SuccessAnim onAnimationComplete={handleAnimationComplete} message={successMessage} />}
      <LoadingScreen isLoading={loading} />


      <div style={{ transition: "opacity 0.1s ease-in-out", opacity: showContent ? 1 : 0}}>
        <div style={{ width: "100%",}}>



        <div style={{display:"flex", flexDirection: window.innerWidth > window.innerHeight ? "row" :"column", justifyContent:"space-around"}}>


        <div style={{display:"flex", flexDirection:"column", width: window.innerWidth > window.innerHeight ? "47%" : "100%"}}>
              <h2>معلومات المستخدم</h2>
              <h4 style={{ fontSize: "18px", color: "gray" }}># {user && user.id}</h4>
          <img
            src={user && user.profileImgUrl}
            alt={`${user && user.fullName}'s profile`}
            style={{ width: "110px", height: "110px", borderRadius: "50%", marginTop:"16px", border:"0.5px solid #ccc", objectFit:"cover" }}
            onClick={()=>{ window.location.href = `https://men-ela-testing.web.app/user/${user.id}`;}}
          />
          <p style={{ marginTop: "16px", marginBottom: "8px" }}>اسم المستخدم</p>
            <input
              placeholder="اسم المستخدم"
              defaultValue={user && user.fullName}
              onChange={(e) => setFullName(e.target.value)}
              style={{
                width: window.innerWidth > window.innerHeight ? "100%" : "100%",
              }}
            />


          <p style={{marginTop:"16px", marginBottom:"8px"}}>مدينة المستخدم (تظهر للكل)</p>
          <select 
  // ref={cityRef} 
  defaultValue={user && user.city}
  id={"input-field"}
  style={{ marginTop: "8px", width:window.innerWidth > window.innerHeight ? "100%" : "100%", }} 
  onChange={(e)=>{
    setUserCity(e.target.value)
  }}
>
  <option value="">المدينة</option>
  {cities.map((city, index) => (
    <option 
      style={{ backgroundColor: "white" }} 
      key={index} 
      value={city.arabic} // Set the English city as the value
    >
      {city.arabic}
    </option>
  ))}
</select>


<p style={{ marginTop: "16px", marginBottom: "8px" }}>عنوان المستخدم للشحن</p>
<input
  placeholder='المدينة'
  defaultValue={user && user.address.city}
  onChange={(e) => setAddressCity(e.target.value)}
  style={{ width: window.innerWidth > window.innerHeight ? "100%" : "100%" }}
/>
<input
  placeholder='الحي'
  defaultValue={user && user.address.neighborhood}
  onChange={(e) => setNeighborhood(e.target.value)}
  style={{ width: window.innerWidth > window.innerHeight ? "100%" : "100%", marginTop: "8px" }}
/>
<input
  placeholder='الشارع'
  defaultValue={user && user.address.street}
  onChange={(e) => setStreet(e.target.value)}
  style={{ width: window.innerWidth > window.innerHeight ? "100%" : "100%", marginTop: "8px" }}
/>
<input
  placeholder='ملاحظات العنوان'
  defaultValue={user && user.address.notes}
  onChange={(e) => setNotes(e.target.value)}
  style={{ width: window.innerWidth > window.innerHeight ? "100%" : "100%", marginTop: "8px" }}
/>



          <p style={{marginTop:"16px", marginBottom:"8px"}}>رصيد المستخدم</p>
          <input placeholder='رصيد المستخدم' readOnly  defaultValue={user && parseFloat(user.balance.toFixed(2))} onChange={(e)=>{}} style={{width:window.innerWidth > window.innerHeight ? "100%" : "100%"}} />
        
          <p style={{ marginTop: "16px", marginBottom: "8px" }}>نسبة العمولة</p>
            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <input
              placeholder="نسبة العمولة"
              defaultValue={user && user.commission}
              onChange={(e) => setCommission(parseFloat(e.target.value))}
              style={{
                width: window.innerWidth > window.innerHeight ? "100%" : "100%",
              }}
            />
            <p style={{marginRight:"6px"}}>%</p>
            </div>
        
        </div>


      <div style={{width: window.innerWidth > window.innerHeight ? "47%" : "100%"}}>
          <p style={{marginTop:"16px", marginBottom:"8px"}}>بنك المستخدم</p>
          <select
              id='input-field'
              style={{marginTop:"8px"}}
              defaultValue={user && user.bank}
              onChange={(e) => {
                setBank(e.target.value);
              }}
            >
              <option disabled value="">البنك</option>
              <option value="الأهلي">الأهلي</option>
              <option value="بنك ساب">بنك ساب</option>
              <option value="مصرف الإنماء">مصرف الإنماء</option>
              <option value="البنك السعودي الفرنسي">البنك السعودي الفرنسي</option>
              <option value="بنك الرياض">بنك الرياض</option>
              <option value="مصرف الراجحي">مصرف الراجحي</option>
              <option value="البنك العربي الوطني">البنك العربي الوطني</option>
              <option value="بنك البلاد">بنك البلاد</option>
              <option value="بنك الجزيرة">بنك الجزيرة</option>
              <option value="البنك السعودي للاستثمار">البنك السعودي للاستثمار</option>
            </select>
{/* 
          <p style={{marginTop:"16px", marginBottom:"8px"}}>تم التوثيق كتاجر</p>
          <select
              id='input-field'
              style={{marginTop:"8px"}}
              defaultValue={isBusiness}
              onChange={(e) => {
                setIsBusiness(e.target.value)
              }}
            >
              <option value={false}>لا</option>
              <option value={true}>نعم</option>
            </select>
            
          <p style={{marginTop:"16px", marginBottom:"8px"}}>تم التوثيق كفرد</p>
          <select
              id='input-field'
              style={{marginTop:"8px"}}
              defaultValue={isVerified}
              disabled={isBusiness}
              onChange={(e) => {
                setIsVerified(e.target.value)
              }}
            >
              <option value={false}>لا</option>
              <option value={true}>نعم</option>
            </select> */}


            <p style={{marginTop:"16px", marginBottom:"8px"}}>رقم السجل التجاري</p>
          <input placeholder='رقم السجل التجاري' readOnly value={businessCRnumber || "لا يوجد"} onChange={(e)=>{}} style={{width:window.innerWidth > window.innerHeight ? "100%" : "100%"}} />

          {
            user && user.businessCRurl && (
              <button className='button' style={{marginTop:"16px", justifyContent:"center", alignItems:"center"}} 
              onClick={downloadCR}
              >
                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                 السجل التجاري  <FaRegEye style={{marginRight:"8px"}} />
                </div>
              </button>
            )
          }
        <p style={{marginTop:"16px", marginBottom:"8px"}}>رقم التوثيق بمركز الأعمال</p>
        <input placeholder='رقم التوثيق بمركز الأعمال' readOnly value={businessVerificationNumber || "لا يوجد"} onChange={(e)=>{}} style={{width:window.innerWidth > window.innerHeight ? "100%" : "100%"}} />

        <p style={{marginTop:"16px", marginBottom:"8px"}}>البريد الإلكتروني</p>
        <input placeholder='البريد الإلكتروني' defaultValue={user && user.email} onChange={(e)=>{setEmail(e.target.value)}} style={{width:window.innerWidth > window.innerHeight ? "100%" : "100%"}} />

        <p style={{marginTop:"16px", marginBottom:"8px"}}>الآيبان</p>
        <input placeholder='الآيبان' defaultValue={user && user.iban} onChange={(e)=>{setIban(e.target.value)}} style={{width:window.innerWidth > window.innerHeight ? "100%" : "100%"}} />


        {
            user && user.ibanCertificateURL && (
              <button className='button' style={{marginTop:"16px", justifyContent:"center", alignItems:"center"}} 
              onClick={downloadIbanLetter}
              >
                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                شهادة الآيبان  <FaRegEye style={{marginRight:"8px"}} />
                </div>
              </button>
            )
        }

          <p style={{marginTop:"16px", marginBottom:"8px"}}>حظر العميل من جميع النشاطات</p>
          <select
              id='input-field'
              style={{marginTop:"8px"}}
              // defaultValue={isBanned}
              value={isBanned}
              onChange={(e) => {
                setIsBanned(e.target.value);
              }}
            >
              <option value={false}>لا</option>
              <option value={true}>نعم</option>
            </select>

          <p style={{marginTop:"16px", marginBottom:"8px"}}>حظر العميل من إضافة منتجات</p>
          <select
              id='input-field'
              style={{marginTop:"8px"}}
              // defaultValue={isBannedFromAddingProducts === true}
              value={isBannedFromAddingProducts}
              onChange={(e) => {
                setIsBannedFromAddingProducts(e.target.value);
              }}
            >
              <option value={false}>لا</option>
              <option value={true}>نعم</option>
            </select>

          <p style={{marginTop:"16px", marginBottom:"8px"}}>حظر العميل من الرسائل الخاصة</p>
          <select
              id='input-field'
              style={{marginTop:"8px"}}
              // defaultValue="لا"
              value={isBannedFromChatting}
              onChange={(e) => {
                setIsBannedFromChatting(e.target.value);
              }}
            >
              <option value={false}>لا</option>
              <option value={true}>نعم</option>
            </select>

          <p style={{marginTop:"16px", marginBottom:"8px"}}>حظر العميل من التعليق</p>
          <select
              id='input-field'
              style={{marginTop:"8px"}}
              // defaultValue="لا"
              value={isBannedFromCommenting}
              onChange={(e) => {
                setIsBannedFromCommenting(e.target.value);
              }}
            >
              <option value={false}>لا</option>
              <option value={true}>نعم</option>
            </select>

          <p style={{marginTop:"16px", marginBottom:"8px"}}>حظر العميل من الإبلاغ</p>
          <select
              id='input-field'
              style={{marginTop:"8px"}}
              // defaultValue="لا"
              value={isBannedFromReporting}
              onChange={(e) => {
                setIsBannedFromReporting(e.target.value);
              }}
            >
              <option value={false}>لا</option>
              <option value={true}>نعم</option>
            </select>

          <p style={{marginTop:"16px", marginBottom:"8px"}}>الترقية لحساب Admin</p>
          <select
              id='input-field'
              style={{marginTop:"8px"}}
              // defaultValue="لا"
              value={isAdmin}
              onChange={(e) => {
                setIsAdmin(e.target.value);
              }}
            >
              <option value={false}>لا</option>
              <option value={true}>نعم</option>
            </select>
            
      </div>


      </div>
      <button className='button' style={{marginTop:"16px"}} onClick={updateUser}>
      <div style={{
  display: "flex",
  flexDirection: "row", // Use column layout to stack items vertically
  alignItems: "center",
  justifyContent: "center",
}}>
  <p style={{
    marginBottom: "0px",
    whiteSpace: "nowrap",
    fontSize: "16px"
  }}>تحديث بيانات العميل</p>
  {updateLoading && (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      marginRight:"8px"
    }}>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}>
        <Oval
          height={"15px"}
          width={"20px"}
          color={"white"}
          wrapperClass=""
          visible={true}
          wrapperStyle={{
            display: "block",
            marginTop: "2px",
            padding: 0
          }}
          ariaLabel='oval-loading'
          secondaryColor="#ccc"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    </div>
  )}
</div>
      </button>
    </div>

      

      </div>
    </>
  );
}

export default UserDetails;
