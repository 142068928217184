import React, { useEffect, useState } from 'react';
import { db } from '../config/firebase';
import { collection, query, where, getDocs, doc, getDoc, orderBy } from 'firebase/firestore';
import User from '../components/User';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import { MdAttachMoney } from "react-icons/md";
import { GoPeople } from "react-icons/go";
import Request from '../components/Request';


function VerificationRequests() {
  const [requests, setRequests] = useState([]);
  const [requestsLoading, setRequestsLoading] = useState(true);
  const [showContent, setShowContent] = useState(false); // New state for controlling content visibility
  const navigate = useNavigate();


  useEffect(() => {
    const fetchRequests = async () => {
      const requestsCollection = collection(db, 'Requests');
      const q = query(requestsCollection, where("status" , '==', "تحت المعالجة"),  orderBy("timestamp", "desc")); // Order by timestamp descending
  
      try {
        const querySnapshot = await getDocs(q);

        // Map over the documents to get the request data
        const requestsData = querySnapshot.docs.map(doc => ({
          uid: doc.id,
          reqNum: doc.data().id,
          ...doc.data()
        }));
  
        // Set the fetched data to state
        setRequests(requestsData);
      } catch (error) {
        console.error('Error fetching requests:', error);
      } finally {
        setTimeout(() => {
          setRequestsLoading(false); // Stop loading after data is fetched
          setShowContent(true); // Trigger visibility change with slight delay
        }, 200); // Adjust the delay as needed
      }
    };
  
    fetchRequests();
  }, []);
  

  return (
    <>
    <LoadingScreen isLoading={requestsLoading} />
        <div style={{ transition: "opacity 0.1s ease-in-out", opacity: showContent ? 1 : 0 }}>
          {/* <h3 style={{ marginBottom: "32px", fontWeight:"bold", fontSize:"24px", display:"flex" }}> <GoPeople size={24} style={{minHeight:"24px", minWidth:"24px", padding:"0px", marginRight:"0px", marginLeft:"8px", marginTop:"4px" }} />قائمة المستخدمين </h3> */}
          {
  requests.length > 0 ? (
    requests.map((request) => (
      <div
        key={request.id}
        onClick={() => {
          navigate(`/requestDetails/${request.uid}`);
        }}
      >
        <Request
          requestType={request.type}
          requestId={request.reqNum}
          userId={request.userId}
        />
      </div>
    ))
  ) : (
    <p>لا توجد طلبات بعد</p>
  )
}

        </div>
    </>
  );
}

export default VerificationRequests;
