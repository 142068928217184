import React, {useEffect, useRef, useState} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LuClipboardList } from "react-icons/lu";
import { toast } from 'react-toastify'; // Import toast
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import LoadingScreen from './LoadingScreen';
import { Divider } from '@mui/material';
import { AiFillStar } from "react-icons/ai";
import { FaStore } from "react-icons/fa";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { GoPaperclip } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { auth, storage } from '../config/firebase'
import { getFunctions, httpsCallable } from 'firebase/functions';
import SuccessAnim from '../components/SuccessAnim';
import { Oval } from 'react-loader-spinner';
import { FaRegEye } from "react-icons/fa";


function RequestDetails() {
  const location = useLocation();
  const {uid} = useParams();

  const [request, setRequest] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showSuccessAnim, setShowSuccessAnim] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [rejectVerifyLoading, setRejectVerifyLoading] = useState(false);

  const handleAnimationComplete = () => {
    setShowSuccessAnim(false); // Reset button state after animation completes
    navigate(-1)
  };
  // const handleCopy = (text) => {
  //   if (navigator.clipboard) {
  //     navigator.clipboard.writeText(text)
  //       .then(() => {
  //         // Create a formatted message with HTML
  //         const message = (
  //           <p style={{ direction: "rtl" }}>
  //             تم نسخ: <span style={{fontWeight:"bold"}}>{text}</span>
  //           </p>
  //         );
  //         toast.success(message); // Show toast on success
  //       })
  //       .catch((error) => {
  //         toast.error('Failed to copy text'); // Show toast on failure
  //         console.error('Failed to copy text: ', error);
  //       });
  //   } else {
  //     toast.error('Clipboard API is not supported'); // Show toast if API not supported
  //   }
  // };

  const [fileName, setFileName] = useState(null)
  const [transferPDF, setTransferPDF] = useState(null)

  const [confirmTransferLoading, setConfirmTransferLoading] = useState(false)

  const transferPDFRef = useRef();

  const navigate = useNavigate()
  const [showContent, setShowContent] = useState(false); // State for content visibility
  const [myId, setMyId] = useState(null); // State for content visibility
  
  const [threeNames, setThreeNames] = useState(""); // State for content visibility
  const [beneficiaryName, setBeneficiaryName] = useState(""); // State for content visibility
  const [fullName, setFullName] = useState(""); // State for content visibility
  const [iban, setIban] = useState(""); // State for content visibility
  const [bank, setBank] = useState(""); // State for content visibility
  
  const [businessCRnumber, setCRNumber] = useState(""); // State for content visibility
  const [businessCRurl, setCRURL] = useState(""); // State for content visibility
  const [businessVerificationNumber, setBusinessVerificationNumber] = useState(""); // State for content visibility

  // fetch request data
  useEffect(() => {
    const fetchRequest = async () => {
      // Fetch request data
      const userDocRef = doc(db, 'Requests', uid); // Reference to the request document

      try {
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          const requestData = { uid: docSnapshot.id, ...docSnapshot.data() };
          setRequest(requestData);
          setThreeNames(requestData.threeNames)
          setCRNumber(requestData.CRNumber)
          setCRURL(requestData.CRUrl)
          setBusinessVerificationNumber(requestData.businessVerificationNumber)
          setFullName(requestData.threeNames && requestData.threeNames.split(" ")[0] + " " + requestData.threeNames.split(" ")[1])
        } else {
          console.error('No such request!');
        }
      } catch (error) {
        console.error('Error fetching request or orders:', error);
      } finally {
          setTimeout(() => {
            setLoading(false);
        }, 300); // Adjust the delay as needed
        setTimeout(() => {
          setShowContent(true); // Trigger visibility change with a slight delay
        }, 300); // Adjust the delay as needed
      }
    };

    fetchRequest();
  }, [uid]); // Dependency array includes `uid` to refetch if it changes

    // fetch my id
    useEffect(() => {
        const fetchCurrentUserId = async () => {
          try {
            const currentUserRef = doc(db, 'Users', auth.currentUser.uid); // Assuming current request's document ID is stored here
            const currentUserSnap = await getDoc(currentUserRef);
    
            if (currentUserSnap.exists()) {
          setMyId(currentUserSnap.data().id)
            } else {
              console.error('User document not found');
            }
          } catch (error) {
            console.error('Error fetching current request:', error);
          }
        };
    
        fetchCurrentUserId();
      }, []);
  
const downloadIbanLetter = () => {
  // Check if the ibanLetterUrl exists in the request object
  if (request?.ibanLetterUrl) {
    const ibanLetterUrl = request.ibanLetterUrl;

    // Trigger the file download by opening the URL in a new tab
    window.open(ibanLetterUrl, '_blank'); // Opens the file in a new tab
  } else {
    console.error("IBAN Letter URL not found in the provided request object.");
    alert("IBAN Letter not available for this request.");
  }
};
const downloadCR = () => {
  // Check if the ibanLetterUrl exists in the request object
  if (businessCRurl) {
    // Trigger the file download by opening the URL in a new tab
    window.open(businessCRurl, '_blank'); // Opens the file in a new tab
  } else {
    console.error("IBAN Letter URL not found in the provided request object.");
    alert("IBAN Letter not available for this request.");
  }
};

const verifyUser = async (userId) => {
  const functions = getFunctions();
  const verifyUserFunction = httpsCallable(functions, 'verifyUser');

  try {
    setVerifyLoading(true)
    const result = await verifyUserFunction({ userId, requestId: uid, threeNames, iban, bank, fullName,  type: request.type, ibanCertificateURL: request.ibanLetterUrl, businessCRnumber , businessCRurl, businessVerificationNumber, beneficiaryName  });

    if (result.data.success) {
      setSuccessMessage("تم قبول الطلب بنجاح!")
      setShowSuccessAnim(true)
      console.log('User verified successfully:', result.data.message);
      // Handle success (e.g., show a notification or update UI)
    } else {
      console.error('Error verifying user:', result.data.message);
      // Handle error (e.g., show an error message)
    }
  } catch (error) {
    console.error('Error calling verifyUser function:', error);
    // Handle error (e.g., show an error message)
  } finally {
    setVerifyLoading(false)
  }
};

const rejectVerificationRequest = async () => {
  const functions = getFunctions();
  const rejectVerification = httpsCallable(functions, 'rejectVerificationRequest');

  try {
    setRejectVerifyLoading(true)
    const result = await rejectVerification({ requestId: uid  });

    if (result.data.success) {
      setSuccessMessage("تم رفض الطلب بنجاح!")
      setShowSuccessAnim(true)
      console.log('User verified successfully:', result.data.message);
      // Handle success (e.g., show a notification or update UI)
    } else {
      console.error('Error verifying user:', result.data.message);
      // Handle error (e.g., show an error message)
    }
  } catch (error) {
    console.error('Error calling verifyUser function:', error);
    // Handle error (e.g., show an error message)
  } finally {
    setRejectVerifyLoading(false)
  }
};

  return (
    <>
{showSuccessAnim && <SuccessAnim onAnimationComplete={handleAnimationComplete} message={successMessage} />}
<LoadingScreen isLoading={loading} />


            <div style={{ padding: "16px", transition: "opacity 0.1s ease-in-out", opacity: showContent ? 1 : 0  }}>
              <h2>نوع الطلب: {request &&  request.type === "Switch to Business" ? "الترقية لحساب تاجر" : "توثيق حساب فرد"}</h2>

              {
                request && request.type !== "Switch to Business" ? (
                  <>
                  

              <h4 style={{marginTop:"16px"}}>معلومات المستخدم:</h4>
              <h4 style={{marginTop:"8px"}}> رقم المستخدم: {request && request.userId}</h4>
              <h4 style={{marginTop:"8px"}}> الاسم الثلاثي: {request && request.threeNames}</h4>
              <h4 style={{marginTop:"8px"}}> رقم الهوية: {request && request.idNumber}</h4>
              <button className='button' style={{marginTop:"16px", justifyContent:"center", alignItems:"center"}} onClick={downloadIbanLetter}>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                شهادة الآيبان  <FaRegEye style={{marginRight:"8px"}} />
                </div>
              </button>



            <div style={{display:"flex", flexDirection:"column", width:"50%", marginTop:"32px"}}>
              <p style={{marginBottom:"4px"}}>اسم المستخدم</p>
            <input type='text' defaultValue={fullName} placeholder='اسم المستخدم' onChange={(e)=>{setFullName(e.target.value)}}/>
              <p style={{marginBottom:"4px",  marginTop:"16px"}}>اسم المستفيد</p>
            <input type='text' defaultValue={threeNames && threeNames} placeholder='الاسم الثلاثي' onChange={(e)=>{setThreeNames(e.target.value)}}/>
              <p style={{ marginTop:"16px"}}>الآيبان</p>
              <input style={{marginTop:"8px"}} type='text' placeholder='الآيبان' onChange={(e)=>{setIban(e.target.value)}}/>
              <p style={{ marginTop:"16px"}}>البنك</p>
              <select
              id='input-field'
              style={{marginTop:"8px"}}
              defaultValue={bank}
              onChange={(e) => {
                setBank(e.target.value);
              }}
            >
              <option disabled value="">البنك</option>
              <option value="الأهلي">الأهلي</option>
              <option value="بنك ساب">بنك ساب</option>
              <option value="مصرف الإنماء">مصرف الإنماء</option>
              <option value="البنك السعودي الفرنسي">البنك السعودي الفرنسي</option>
              <option value="بنك الرياض">بنك الرياض</option>
              <option value="مصرف الراجحي">مصرف الراجحي</option>
              <option value="البنك العربي الوطني">البنك العربي الوطني</option>
              <option value="بنك البلاد">بنك البلاد</option>
              <option value="بنك الجزيرة">بنك الجزيرة</option>
              <option value="البنك السعودي للاستثمار">البنك السعودي للاستثمار</option>
            </select>
            </div>

              <div style={{display:"flex", marginTop:"32px",}}>
              <button disabled={verifyLoading || fullName === "" || threeNames === "" || iban === "" || bank === ""} className='button' style={{ justifyContent:"center", alignItems:"center"}} onClick={()=>{verifyUser(request.userId)}}>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
               {
                verifyLoading ? (
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}>
                    <Oval
                      height={"15px"}
                      width={"20px"}
                      color={"white"}
                      wrapperClass=""
                      visible={true}
                      wrapperStyle={{
                        display: "block",
                        marginTop: "2px",
                        padding: 0
                      }}
                      ariaLabel='oval-loading'
                      secondaryColor="#ccc"
                      strokeWidth={1}
                      strokeWidthSecondary={1}
                    />
                  </div>
                ) : (
                  "قبول الطلب"
                )
               }
                </div>
              </button>
              <button disabled={verifyLoading} className='button' style={{justifyContent:"center", alignItems:"center", marginRight:"16px", backgroundColor:"#F00057"}} onClick={downloadIbanLetter}>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                رفض الطلب
                </div>
              </button>
              </div>

              </>
                ) : (
                  <>
                  

                  <h4 style={{marginTop:"16px"}}>معلومات المستخدم:</h4>
                  <h4 style={{marginTop:"8px"}}> رقم المستخدم: {request && request.userId}</h4>
                  <h4 style={{marginTop:"8px"}}>اسم المتجر: {request && request.storeName}</h4>
                  <h4 style={{marginTop:"8px"}}> رقم السجل التجاري: {request && request.CRNumber}</h4>
                  <h4 style={{marginTop:"8px"}}> رقم التوثيق بمركز الأعمال: {request && request.businessVerificationNumber}</h4>
                  <button className='button' style={{marginTop:"16px", justifyContent:"center", alignItems:"center"}} onClick={downloadIbanLetter}>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    شهادة الآيبان  <FaRegEye style={{marginRight:"8px"}} />
                    </div>
                  </button>
                  <button className='button' style={{marginTop:"16px", justifyContent:"center", alignItems:"center", marginRight:"8px"}} onClick={downloadCR}>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                     السجل التجاري <FaRegEye style={{marginRight:"8px"}} />
                    </div>
                  </button>
    
    
    
                <div style={{display:"flex", flexDirection:"column", width:"50%", marginTop:"32px"}}>
                  <p style={{marginBottom:"4px"}}>اسم المتجر</p>
                <input type='text' defaultValue={request && request.storeName} placeholder='اسم المستخدم' onChange={(e)=>{setFullName(e.target.value)}}/>
                  <p style={{marginBottom:"4px",  marginTop:"16px"}}>اسم المستفيد</p>
                <input type='text' placeholder='اسم المستفيد' onChange={(e)=>{setBeneficiaryName(e.target.value)}}/>
                  <p style={{ marginTop:"16px"}}>الآيبان</p>
                  <input style={{marginTop:"8px"}} type='text' placeholder='الآيبان' onChange={(e)=>{setIban(e.target.value)}}/>
                  <p style={{ marginTop:"16px"}}>البنك</p>
                  <select
                  id='input-field'
                  style={{marginTop:"8px"}}
                  defaultValue={bank}
                  onChange={(e) => {
                    setBank(e.target.value);
                  }}
                >
                  <option disabled value="">البنك</option>
                  <option value="الأهلي">الأهلي</option>
                  <option value="بنك ساب">بنك ساب</option>
                  <option value="مصرف الإنماء">مصرف الإنماء</option>
                  <option value="البنك السعودي الفرنسي">البنك السعودي الفرنسي</option>
                  <option value="بنك الرياض">بنك الرياض</option>
                  <option value="مصرف الراجحي">مصرف الراجحي</option>
                  <option value="البنك العربي الوطني">البنك العربي الوطني</option>
                  <option value="بنك البلاد">بنك البلاد</option>
                  <option value="بنك الجزيرة">بنك الجزيرة</option>
                  <option value="البنك السعودي للاستثمار">البنك السعودي للاستثمار</option>
                </select>
                </div>
    
                  <div style={{display:"flex", marginTop:"32px",}}>
                  <button disabled={verifyLoading || rejectVerifyLoading || fullName === "" || threeNames === "" || iban === "" || bank === ""} className='button' style={{ justifyContent:"center", alignItems:"center"}} onClick={()=>{verifyUser(request.userId)}}>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                   {
                    verifyLoading ? (
                      <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}>
                        <Oval
                          height={"15px"}
                          width={"20px"}
                          color={"white"}
                          wrapperClass=""
                          visible={true}
                          wrapperStyle={{
                            display: "block",
                            marginTop: "2px",
                            padding: 0
                          }}
                          ariaLabel='oval-loading'
                          secondaryColor="#ccc"
                          strokeWidth={1}
                          strokeWidthSecondary={1}
                        />
                      </div>
                    ) : (
                      "قبول الطلب"
                    )
                   }
                    </div>
                  </button>
                  <button disabled={verifyLoading || rejectVerifyLoading} className='button' style={{justifyContent:"center", alignItems:"center", marginRight:"16px", backgroundColor:"#F00057"}} onClick={rejectVerificationRequest}>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    {
                    rejectVerifyLoading ? (
                      <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}>
                        <Oval
                          height={"15px"}
                          width={"20px"}
                          color={"white"}
                          wrapperClass=""
                          visible={true}
                          wrapperStyle={{
                            display: "block",
                            marginTop: "2px",
                            padding: 0
                          }}
                          ariaLabel='oval-loading'
                          secondaryColor="#ccc"
                          strokeWidth={1}
                          strokeWidthSecondary={1}
                        />
                      </div>
                    ) : (
                      "رفض الطلب"
                    )
                   }
                    </div>
                  </button>
                  </div>
    
                  </>
                )
              }


          </div>
    </>
  );
}

export default RequestDetails;
