import React, { useEffect, useState } from 'react';
import { db } from '../config/firebase';
import { collection, query, where, getDocs, doc, getDoc, orderBy } from 'firebase/firestore';
import User from '../components/User';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import { MdAttachMoney } from "react-icons/md";
import { GoPeople } from "react-icons/go";


function Users() {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [showContent, setShowContent] = useState(false); // New state for controlling content visibility
  const navigate = useNavigate();


  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'Users');
      const q = query(usersCollection, orderBy("joinedDate", "desc")
      );
  
      try {
        const querySnapshot = await getDocs(q);
        const usersData = [];
  
        // Collect user IDs
        const userIds = querySnapshot.docs.map(doc => doc.id);
  
        // Use a batch to fetch user data
        const userFetchPromises = userIds.map(async (userId) => {
          const userDocRef = doc(db, 'Users', userId);
          const userSnapshot = await getDoc(userDocRef);
  
          if (userSnapshot.exists()) {
            return { uid: userId, ...userSnapshot.data() }; // Include user ID in the returned data
          } else {
            console.error(`User with ID ${userId} not found.`);
            return null; // Return null for not found users
          }
        });
  
        // Wait for all user fetch promises to resolve
        const resolvedUsersData = await Promise.all(userFetchPromises);
  
        // Filter out null entries (users not found)
        setUsers(resolvedUsersData.filter(user => user !== null));
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setTimeout(() => {
          setUsersLoading(false); // Ensure this runs after data fetching completes
        }, 200); // Adjust the delay as needed
        setTimeout(() => {
          setShowContent(true); // Trigger visibility change with a slight delay
        }, 200); // Adjust the delay as needed
      }
    };
  
    fetchUsers();
  }, []);

  return (
    <>
    <LoadingScreen isLoading={usersLoading} />
        <div style={{ transition: "opacity 0.1s ease-in-out", opacity: showContent ? 1 : 0 }}>
          {/* <h3 style={{ marginBottom: "32px", fontWeight:"bold", fontSize:"24px", display:"flex" }}> <GoPeople size={24} style={{minHeight:"24px", minWidth:"24px", padding:"0px", marginRight:"0px", marginLeft:"8px", marginTop:"4px" }} />قائمة المستخدمين </h3> */}
          {
  users.length > 0 ? (
    users.map((user) => (
      <div
        key={user.id}
        onClick={() => {
          navigate(`/users/${user.uid}`);
        }}
      >
        <User
          img={user.profileImgUrl}
          fullName={user.fullName}
          balance={null}
          userId={user.id}
          joinedDate={user.joinedDate}
        />
      </div>
    ))
  ) : (
    <p>لا يوجد مستخدمون بعد</p>
  )
}

        </div>
    </>
  );
}

export default Users;
