import React, { useEffect, useState } from 'react';
import { db } from '../config/firebase';
import { collection, query, where, getDocs, orderBy, getDoc, doc } from 'firebase/firestore';
import Transfer from '../components/Transfer';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import { BiTransferAlt } from "react-icons/bi";

function TransfersScreen() {
  const [transfers, setTransfers] = useState([]);
  const [transfersLoading, setTransfersLoading] = useState(true);
  const [showContent, setShowContent] = useState(false); // New state for controlling content visibility
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransfers = async () => {
      const transfersCollection = collection(db, 'BankTransfers');
      const q = query(transfersCollection, orderBy("timestamp", "desc"));
  
      try {
        const querySnapshot = await getDocs(q);
        const transfersData = [];
  
        // Collect all transferredBy user IDs
        const userFetchPromises = querySnapshot.docs.map(async (docc) => {
          const transferData = docc.data();
          const transferredById = transferData.transferredBy;

          // Fetch the user data for the `transferredBy` field
          const userDocRef = doc(db, 'Users', transferredById);
          const userSnapshot = await getDoc(userDocRef);
          
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            return {
              ...transferData,
              uid: doc.id,
              transferredByData: userData, // Attach the user data to the transfer
            };
          } else {
            console.error(`User with ID ${transferredById} not found.`);
            return {
              ...transferData,
              uid: doc.id,
              transferredByData: null, // User not found
            };
          }
        });
  
        // Wait for all user fetch promises to resolve
        const resolvedTransfersData = await Promise.all(userFetchPromises);
  
        setTransfers(resolvedTransfersData);
      } catch (error) {
        console.error('Error fetching transfers:', error);
      } finally {
          setTimeout(() => {
            setTransfersLoading(false); 
        }, 200); // Adjust the delay as needed
        setTimeout(() => {
          setShowContent(true); // Trigger visibility change with a slight delay
        }, 300); // Adjust the delay as needed
      }
    };
  
    fetchTransfers();
  }, []);

  return (
    <>
    <LoadingScreen isLoading={transfersLoading} />
        <div style={{ transition: "opacity 0.1s ease-in-out", opacity: showContent ? 1 : 0 }}>
          {/* <h3 style={{ marginBottom: "32px", fontWeight:"bold", fontSize:"24px", display:"flex" }}> <BiTransferAlt size={32} style={{minHeight:"32px", minWidth:"32px", marginLeft:"8px" }} /> الحوالات البنكية</h3> */}
          {
  transfers.length > 0 ? (
    transfers.map((transfer) => (
      <div key={transfer.id}>
        <Transfer
          amount={transfer.amount}
          transferId={transfer.id}
          seller={transfer.sellerId}
          transferer={transfer.transferredByData.fullName}
        />
      </div>
    ))
  ) : (
    <p>لا توجد حوالات بعد</p>
  )
}

        </div>
    </>
  );
}

export default TransfersScreen;
