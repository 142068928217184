import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import successAnimationData from '../assets/animations/success.json';

const SuccessAnim = ({ onAnimationComplete, message }) => {
  useEffect(() => {
    // Disable scrolling when the success animation is visible
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore default overflow style
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []); // Ensure it runs only once when the component mounts

  const handleAnimationComplete = () => {
    // Execute the callback function provided by the parent component
    onAnimationComplete();
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      }}
    >
      {/* Success animation */}
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '16px',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
          padding: '12px 16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        className='pop-up'
      >
        <Lottie
          style={{ width: '130px', height: '130px' }}
          animationData={successAnimationData}
          loop={false}
          onComplete={handleAnimationComplete} // Callback when animation completes
        />
        <p style={{ marginBottom: '0px', textAlign: 'center', fontWeight: '500' }}>
          {message}
        </p>
      </div>
    </div>
  );
};

export default SuccessAnim;
