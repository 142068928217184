import './App.css';
import { AiOutlineHome } from "react-icons/ai";
import { GoPeople } from "react-icons/go";
import { BsBank2 } from "react-icons/bs";
import { BrowserRouter, NavLink, Outlet, Route, Routes, Navigate } from 'react-router-dom';
import Logo from './assets/logo.png';
import Navbar from './components/Navbar';
import HomeScreen from './screens/HomeScreen';
import UsersWithBalance from './screens/UsersWithBalance';
import TransferToUserScreen from './screens/TransferToUserScreen';
import SignInScreen from './screens/SignInScreen';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth } from './config/firebase';
import { useState, useEffect } from 'react';  // Import React hooks
import { MdAttachMoney } from "react-icons/md";
import TransfersScreen from './screens/TransfersScreen';
import { BiTransferAlt } from "react-icons/bi";
import UsersScreen from './screens/UsersScreen';
import { IoCloseOutline } from "react-icons/io5";
import { PiCoinsLight } from "react-icons/pi";
import { VscVerified } from "react-icons/vsc";
import VerificationRequests from './screens/VerificationRequests';
import RequestDetails from './screens/RequestDetails';
import UserDetails from './screens/UserDetails';
import LoadingScreen from './screens/LoadingScreen';


function App() {


  



  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // Initial call to set the state with current window dimensions
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(windowSize.width > windowSize.height)
  const [shouldRenderDrawer, setShouldRenderDrawer] = useState(windowSize.width > windowSize.height);




  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
  
      setWindowSize({
        width: newWidth,
        height: newHeight
      });
  
      // Open drawer by default in landscape, close in portrait
      setDrawerOpen(newWidth > newHeight);
      setShouldRenderDrawer(newWidth > newHeight);
    };
  
    window.addEventListener('resize', handleResize);
  
    // Initial call to set the state with current window dimensions
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Open the drawer smoothly
  const openDrawer = () => {
    setShouldRenderDrawer(true);
    setTimeout(() => setDrawerOpen(true), 10); // Small delay to allow render before transition
  };

  // Close the drawer smoothly
  const closeDrawer = () => {
    setDrawerOpen(false); // Triggers the CSS transition to close the drawer
  };

  // When the drawer is closed, delay the removal from the DOM to match the transition duration
  useEffect(() => {
    if (!drawerOpen) {
      setTimeout(() => setShouldRenderDrawer(false), 300); // Ensure this matches your CSS transition time (300ms)
    }
  }, [drawerOpen]);


  useEffect(() => {
    // Listen for changes in authentication state
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false); // Stop loading once auth state is determined
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);


  if (loading) {
    return <LoadingScreen isLoading={loading} isAppJs={true} />
  }


  return (
    <BrowserRouter>
      <Routes>
        {/* Sign In Route */}
        <Route path="/sign_in" element={<SignInScreen />} />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            user ? ( // Check if the user is authenticated
              <div style={{ maxWidth: "100%", height: "100vh" }}>
                <div style={{ display: "flex", direction: "rtl", height: "fit-content" }}>
                  {/* drawer */}
                  {/* <button onClick={openDrawer}>Open</button> */}
                  {/* {shouldRenderDrawer && ( */}
  <div
    style={{
      width: window.innerWidth > window.innerHeight ? "20%" : "100%",
      height: "fit-content",
      position: "fixed",
      top: 0,
      right: 0,  // Position the drawer on the right
      // zIndex: window.innerWidth > window.innerHeight ? 0 : 10,
      // boxShadow: window.innerWidth > window.innerHeight ? "none" : '-2px 0px 5px rgba(0, 0, 0, 0.2)',  // Shadow on the left side
      marginTop: window.innerWidth > window.innerHeight ? "75px" : "55px",
      // padding:"0px 16px",
      backgroundColor: window.innerWidth > window.innerHeight ? "transparent" : "white",
      overflowX: "hidden",
      padding:"0px 16px",
      boxSizing:"border-box",
      zIndex:window.innerWidth > window.innerHeight ? 1 : 2
    }}
  >

    {/* Drawer content goes here */}
    <div className="drawer-content">
      <div id='no-scroll-bar' style={{display:"flex", flexDirection: window.innerWidth > window.innerHeight ? "column"  : "row", paddingBottom:"8px",backgroundColor: window.innerWidth > window.innerHeight ? "transparent" : "white", marginTop: window.innerWidth > window.innerHeight ? "0px" : "4px", width:"100%", maxWidth:"100%", borderBottom: window.innerWidth > window.innerHeight ? "0px" : "0.5px solid #ccc", zIndex: window.innerWidth > window.innerHeight ? -1 : 2}}>
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? 'sidebar-active' : 'sidebar-inActive'
          }
        onClick={() => window.innerWidth < window.innerHeight ? closeDrawer() : null}

        >
          <span>
            <AiOutlineHome size={19} style={{ marginLeft: "8px", minHeight: "19px", minWidth: "19px" }} />الرئيسية
          </span>
        </NavLink>

        <NavLink
        onClick={() => window.innerWidth < window.innerHeight ? closeDrawer() : null}

          to="/users"
          className={({ isActive }) =>
            isActive ? 'sidebar-active' : 'sidebar-inActive'
          }
        >
          <span>
            <GoPeople size={19} style={{ marginLeft: "8px", minHeight: "19px", minWidth: "19px" }} />المستخدمين
          </span>
        </NavLink>

        <NavLink
        onClick={() => window.innerWidth < window.innerHeight ? closeDrawer() : null}
          to="/bank_transfers"
          className={({ isActive }) =>
            isActive ? 'sidebar-active' : 'sidebar-inActive'
          }
        >
          <span>
            <BiTransferAlt size={19} style={{ marginLeft: "8px", minHeight: "19px", minWidth: "19px" }} />الحوالات البنكية
          </span>
        </NavLink>

        <NavLink
        onClick={() => window.innerWidth < window.innerHeight ? closeDrawer() : null}
          to="/users_with_balance"
          className={({ isActive }) =>
            isActive ? 'sidebar-active' : 'sidebar-inActive'
          }
        >
          <span>
            <PiCoinsLight size={19} style={{ marginLeft: "8px", minHeight: "19px", minWidth: "19px" }} />قائمة المستخدمين و المبالغ المستحقة
          </span>
        </NavLink>

        <NavLink
        onClick={() => window.innerWidth < window.innerHeight ? closeDrawer() : null}
          to="/verification_requests"
          className={({ isActive }) =>
            isActive ? 'sidebar-active' : 'sidebar-inActive'
          }
        >
          <span>
            <VscVerified size={19} style={{ marginLeft: "8px", minHeight: "19px", minWidth: "19px" }} />طلبات التوثيق
          </span>
        </NavLink>
      </div>
    </div>
  </div>
                  {/* // )} */}
                  {/* navbar */}
                  <Navbar openDrawer={openDrawer} />
                  <div 
                  onClick={() =>  {
                    if(window.innerWidth < window.innerHeight) closeDrawer();
                  }}

                  style={{
                    marginTop: window.innerWidth > window.innerHeight ? "75px" : "135px",
                    marginRight: window.innerWidth > window.innerHeight  ? "calc(20% + 16px)" : "16px",
                    zIndex: 0,
                    width: "100%",
                    backgroundColor:"white",
                    padding:"16px",
                    border:"1.5px solid #00C8B8",
                    borderRadius: "24px",
                    marginLeft:"16px",
                    marginBottom:"16px",
                    height:"fit-content",
                    paddingBottom:"24px",
                    minHeight:  window.innerWidth > window.innerHeight ? "calc(100vh - 135px)" : "calc(80vh - 135px)",
                    
                  }}>
                    <Outlet />
                  </div>
                </div>
                <ToastContainer autoClose={2000} /> {/* Add ToastContainer here */}
              </div>
            ) : (
              <Navigate to="/sign_in" /> // Redirect to sign-in page if no user is authenticated
            )
          }
        >
          <Route index path="/" element={<HomeScreen />} />
          <Route path="/users" element={
            <UsersScreen />
            } />
          <Route path="/users/:uid" element={
            <UserDetails />
            } />
          <Route path="/bank_transfers" element={<TransfersScreen />} />
          <Route path="/users_with_balance" element={<UsersWithBalance />} />
          <Route path="/verification_requests" element={<VerificationRequests />} />
          <Route path="/transferToUser/:uid" element={<TransferToUserScreen />} />
          <Route path="/requestDetails/:uid" element={<RequestDetails />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
