import React from 'react'
import { Divider } from '@mui/material';
import { BsCalendarDate } from "react-icons/bs";

function User ({img, fullName, balance, userId, joinedDate}) {
        return (
            <div className='user'>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <div style={{display:"flex"}}>

                    <img src={img} style={{width:"60px", height:"60px", borderRadius:"50%", objectFit:"cover"}}/>
                    <div style={{ height:"100%", display:"flex", flexDirection:"column", marginRight:"8px", marginBottom:"auto"}}>
                    <p style={{marginRight: "4px", fontWeight:500}}>{fullName}</p>
                    <p style={{marginRight: "4px", fontSize:"13px", color:"gray"}}># {userId}</p>
                    {
    joinedDate && (
        <p style={{ marginRight: "4px", fontSize: "13px", color: "gray" }}>
            <BsCalendarDate style={{marginLeft:"4px", marginTop:"2px"}} /> {joinedDate.toDate().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            })}
        </p>
    )
}

                    </div>
                    </div>
                    {
                        balance && (

                            <div style={{display:"flex", justifyContent:"center", alignItems:"center" }}>
                    <p style={{ marginBottom:"0px"}}>الرصيد:</p>
                    <h3 style={{marginBottom:"0px", marginRight:"8px", }}>{parseFloat(balance).toFixed(2)}</h3>
                    <p style={{ marginRight:"8px"}}>ر.س</p>
                    </div>
                    )
                }

                </div>

                <Divider sx={{width:"100%", marginTop:"8px"}} />

            </div>
        );
}
 
export default User;