import React from 'react'
import { Divider } from '@mui/material';
import { BsCalendarDate } from "react-icons/bs";

function Request ({requestType, requestId, date, userId}) {

    let type = requestType === "Switch to Business" ? "الترقية لحساب تاجر" : "توثيق حساب فرد"
        return (
            <div className='request'>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <div style={{display:"flex"}}>
                    <div style={{ height:"100%", display:"flex", flexDirection:"column", marginRight:"8px", marginBottom:"auto"}}>
                    <p style={{marginBottom:"8px"}}>رقم الطلب # <span style={{fontWeight:500}}>{requestId}</span></p>
                    <p style={{marginRight: "4px", fontWeight:500, marginBottom:"8px"}}>نوع الطلب: {type}</p>
                    <p style={{marginRight: "4px", fontWeight:500, marginBottom:"8px"}}> رقم المستخدم: {userId}</p>
                    {
    date && (
        <p style={{ marginRight: "4px", fontSize: "13px", color: "gray" }}>
            <BsCalendarDate style={{marginLeft:"4px", marginTop:"2px"}} /> {date.toDate().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            })}
        </p>
    )
}

                    </div>
                    </div>
                </div>

                <Divider sx={{width:"100%", margin:"16px"}} />

            </div>
        );
}
 
export default Request;