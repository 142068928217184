import React from 'react'
import { Divider } from '@mui/material';

function Transfer ({transferId, amount, transferer, seller, timestamp}) {
        return (
            <div className='transfer'>
                <div style={{justifyContent:"flex-start"}}>
                <div style={{display:"flex", marginBottom:"16px", justifyContent:"flex-start"}}>
                        <div style={{ height:"100%", display:"flex", flexDirection:"column", marginRight:"8px", marginBottom:"auto"}}>
                        <p >حوالة رقم # <span style={{fontWeight:500}}>{transferId}</span></p>
                        </div>
                </div>
                <div style={{display:"flex", justifyContent: "space-between", flexDirection: window.innerWidth > window.innerHeight ? "row" : "column", alignItems:"flex-start", padding:"0px"}}>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginBottom:"0px" }}>
                        <p style={{ marginBottom:"0px"}}>تم تحويلها من الموظف:</p>
                        <h3 style={{marginBottom:"0px", marginRight:"8px", }}>{transferer}</h3>
                    </div>

                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginBottom:"0px", marginTop: window.innerWidth > window.innerHeight ? "0px" : "8px"}}>
                        <p style={{ marginBottom:"0px"}}>المبلغ:</p>
                        <h3 style={{marginBottom:"0px", marginRight:"8px", }}>{parseFloat(amount).toFixed(2)}</h3>
                        <p style={{ marginRight:"8px", marginBottom:"0px"}}>ر.س</p>
                    </div>

                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginBottom:"0px", marginTop: window.innerWidth > window.innerHeight ? "0px" : "8px" }}>
                        <p style={{ marginBottom:"0px"}}>المستفيد:</p>
                        <h3 style={{marginBottom:"0px", marginRight:"8px", }}>{seller}</h3>
                    </div>
                    </div>

                </div>

                <Divider sx={{width:"100%", margin:"16px 0px"}} />

            </div>
        );
}
 
export default Transfer;