import React, {useEffect, useRef, useState} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LuClipboardList } from "react-icons/lu";
import { toast } from 'react-toastify'; // Import toast
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import LoadingScreen from './LoadingScreen';
import { Divider } from '@mui/material';
import { AiFillStar } from "react-icons/ai";
import { FaStore } from "react-icons/fa";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { GoPaperclip } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { auth, storage } from '../config/firebase'
import { getFunctions, httpsCallable } from 'firebase/functions';
import SuccessAnim from '../components/SuccessAnim';
import { Oval } from 'react-loader-spinner';


function TransferToUserScreen() {
  const location = useLocation();
  const {uid} = useParams();

  const [user, setUser] = useState(null)
  const [orders, setOrders] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showSuccessAnim, setShowSuccessAnim] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleAnimationComplete = () => {
    setShowSuccessAnim(false); // Reset button state after animation completes
    navigate(-1)
  };
  const handleCopy = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
        .then(() => {
          // Create a formatted message with HTML
          const message = (
            <p style={{ direction: "rtl" }}>
              تم نسخ: <span style={{ fontWeight: "bold" }}>{text}</span>
            </p>
          );
          toast.success(message); // Show toast on success
        })
        .catch((error) => {
          toast.error('Failed to copy text'); // Show toast on failure
          console.error('Failed to copy text: ', error);
        });
    } else {
      // Fallback for older iOS or unsupported environments
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px'; // Hide the element off-screen
      document.body.appendChild(textarea);
      textarea.select();
  
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          const message = (
            <p style={{ direction: "rtl" }}>
              تم نسخ: <span style={{ fontWeight: "bold" }}>{text}</span>
            </p>
          );
          toast.success(message); // Show toast on success
        } else {
          toast.error('Failed to copy text');
        }
      } catch (error) {
        toast.error('Failed to copy text');
        console.error('Failed to copy text: ', error);
      }
  
      document.body.removeChild(textarea); // Clean up the textarea element
    }
  };
  

  const [fileName, setFileName] = useState(null)
  const [transferPDF, setTransferPDF] = useState(null)

  const [confirmTransferLoading, setConfirmTransferLoading] = useState(false)

  const transferPDFRef = useRef();

  const navigate = useNavigate()
  const [showContent, setShowContent] = useState(false); // State for content visibility
  const [myId, setMyId] = useState(null); // State for content visibility

  // fetch seller's data
  useEffect(() => {
    const fetchUserAndOrders = async () => {
      // Fetch user data
      const userDocRef = doc(db, 'Users', uid); // Reference to the user document

      try {
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          const userData = { uid: docSnapshot.id, ...docSnapshot.data() };
          setUser(userData);

          // Fetch orders for the user
          const ordersCollection = collection(db, 'Orders');
          const ordersQuery = query(ordersCollection, 
            where('sellerId', '==', userData.id), 
            where('type', '==', "Purchase"), 
            where('isReceived', '==', true), 
            where('payment_status', '==', "success"), 
            where('admin_bank_transfer_state', '==', "جديد")
        );
          const ordersSnapshot = await getDocs(ordersQuery);
          const ordersData = [];

          ordersSnapshot.forEach((doc) => {
            ordersData.push({ id: doc.id, ...doc.data() });
          });

          setOrders(ordersData);
        } else {
          console.error('No such user!');
        }
      } catch (error) {
        console.error('Error fetching user or orders:', error);
      } finally {
          setTimeout(() => {
            setLoading(false);
        }, 300); // Adjust the delay as needed
        setTimeout(() => {
          setShowContent(true); // Trigger visibility change with a slight delay
        }, 300); // Adjust the delay as needed
      }
    };

    fetchUserAndOrders();
  }, [uid]); // Dependency array includes `uid` to refetch if it changes

  const [currentUserId, setCurrentUserId] = useState(null);

    // fetch my id
    useEffect(() => {
        const fetchCurrentUserId = async () => {
          try {
            const currentUserRef = doc(db, 'Users', auth.currentUser.uid); // Assuming current user's document ID is stored here
            const currentUserSnap = await getDoc(currentUserRef);
    
            if (currentUserSnap.exists()) {
          setMyId(currentUserSnap.data().id)
            } else {
              console.error('User document not found');
            }
          } catch (error) {
            console.error('Error fetching current user:', error);
          }
        };
    
        fetchCurrentUserId();
      }, []);


  const handleFileChange = () => {
    if (transferPDFRef.current && transferPDFRef.current.files.length > 0) {
      setFileName(transferPDFRef.current.files[0].name);
      setTransferPDF(transferPDFRef.current.files[0])
    } else {
      setFileName("");
    }
  };


  async function handleTransfer(orderIds, amount) {
    const functions = getFunctions(); // Initialize Firebase functions
const uploadTransfer = httpsCallable(functions, 'uploadTransferPDFAndHandleBankTransfer');

    try {
      setConfirmTransferLoading(true);
  
      // Convert the file to Base64
      const base64TransferPDF = await convertFileToBase64(transferPDF);
  
      // Call the Firebase Cloud Function
      const result = await uploadTransfer({
        transferPDF: base64TransferPDF, // Base64 encoded PDF
        userId: user.id,
        orderIds: orderIds,
        amount: amount,
        sellerUID: user.uid
      });
  
      // Handle the result of the cloud function call
      if (result.data.success) {
        setTransferPDF(null);
        setFileName("");
        setShowSuccessAnim(true);
        setSuccessMessage("تم تأكيد تحويل المبلغ");
        console.log('Transfer processed successfully:', result.data.message);
      } else {
        console.error('Transfer failed:', result.data.message);
      }
    } catch (error) {
      console.error('Error calling transfer function:', error);
    } finally {
      setConfirmTransferLoading(false);
    }
  }
  

  function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Extract Base64 string from data URL
        resolve(reader.result.split(',')[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file); // Read the file as a data URL
    });
  }
  

  return (
    <>
{showSuccessAnim && <SuccessAnim onAnimationComplete={handleAnimationComplete} message={successMessage} />}
<LoadingScreen isLoading={loading} />


            <div style={{ transition: "opacity 0.1s ease-in-out", opacity: showContent ? 1 : 0  }}>
            <div style={{ width: window.innerWidth > window.innerHeight ? "80%" : "100%" }}>
              <div style={{ display: "flex", justifyContent: "space-between" , flexDirection: window.innerWidth > window.innerHeight ? "row" : "column" }}>
                <div style={{ display: "flex" }}>
                  <img
                    src={user && user.profileImgUrl}
                    alt={`${user && user.fullName}'s profile`}
                    style={{ width: "110px", height: "110px", borderRadius: "50%", border:"0.5px solid #ccc" }}
                    onClick={()=>{ window.location.href = `https://men-ela-testing.web.app/user/${user.id}`;}}
                  />
                  <div style={{ marginRight: "16px" }}>
                    <h4 style={{ fontSize: "18px" }}>{user && user.fullName}</h4>
                    <h4 style={{ fontSize: "18px", color: "gray" }}># {user && user.id}</h4>
                  </div>
                </div>
      
                <ul style={{ listStyle: "none", width: window.innerWidth > window.innerHeight ? "50%" : "100%", padding: 0, margin: 0 }}>
                  <li style={{ display: "flex", alignItems: "center", marginBottom: "8px", padding: "8px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <h4 style={{ marginRight: "16px", fontSize: "18px", fontWeight:"400"}}>الأيبان: <span style={{fontWeight:"500"}}>{user && user.iban}</span>
                      
                      </h4>
                      <button
                        className='button'
                        style={{ marginRight: "auto" }}
                        onClick={() => handleCopy(user.iban)}
                      >
                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <LuClipboardList size={18} style={{marginLeft:"4px"}}/> نسخ
                        </div>
                      </button>
                    </div>
                  </li>
                  <li style={{ display: "flex", alignItems: "center", marginBottom: "8px", padding: "8px" }}>
                    <h4 style={{ marginRight: "16px", fontSize: "18px", fontWeight:"400"}}>اسم المستفيد:<span style={{fontWeight:"500"}}> {user && user.beneficiaryName}</span></h4>
                    <button
                      className='button'
                      style={{ marginRight: "auto" }}
                      onClick={() => handleCopy(user.beneficiaryName)}
                    >
                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <LuClipboardList size={18} style={{marginLeft:"4px"}}/> نسخ
                        </div>
                    </button>
                  </li>
                  <li style={{ display: "flex", alignItems: "center", padding: "8px" }}>
                    <h4 style={{ marginRight: "16px", fontSize: "18px", fontWeight:"400"}}>الرصيد: <span style={{fontSize:"18px", backgroundColor:"#C6EFCE", padding:"8px 12px", borderRadius:"50px", color:"#006100", fontWeight:500}}> {user && parseFloat(user.balance).toFixed(2)}
{" "}
          ر.س       
                    </span>
                    
                    </h4>
                    <button
                      className='button'
                      style={{ marginRight: "auto" }}
                      onClick={() => handleCopy(user.balance)}
                    >
                                              <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <LuClipboardList size={18} style={{marginLeft:"4px"}}/> نسخ
                        </div>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
      



            <div style={{ marginTop: "64px" }}>
                <h2>ملخص طلبات البائع</h2>

                {orders && orders.length > 0 ? (
                <>
                    <ul style={{ listStyle: "none", marginTop: "16px", padding: "0" }}>
                    {orders.map((order) => (
                        <li key={order.id} style={{ marginBottom: "16px" }}>
                        <div style={{ display: "flex" }}>
                            <img
                            src={order.productData.imgs[0]}
                            alt="Product"
                            style={{ width: "70px", height: "70px", borderRadius: "50%" }}
                            />
                            <div style={{ marginRight: "16px" }}>
                            <h3>رقم الطلب # {order.id}</h3>
                            <h4 style={{ marginTop: "8px" }}>اجمالي الطلب {order.amount}</h4>
                            <h4 style={{ marginTop: "8px" }}>اسم المنتج {order.productData.productName}</h4>
                            <h4 style={{ marginTop: "8px" }}>رقم المنتج {order.productData.id}</h4>
                            </div>
                        </div>
                        <Divider sx={{ width: "100%", marginTop: "16px" }} />
                        </li>
                    ))}
                    </ul>

                    {/* Calculate total and amount after deducting the fee */}
                    <div style={{ marginTop: "32px" }}>
                    <h3>
                        إجمالي المبيعات:{" "}
                        {orders.reduce((sum, order) => sum + order.amount, 0).toFixed(2)} ر.س
                    </h3>
                    <h3 style={{ marginTop: "16px" }}>
                        المبلغ بعد خصم الرسوم:{" "}
                        <span style={{ fontSize: "18px", backgroundColor: "#C6EFCE", padding: "8px 12px", borderRadius: "50px", color: "#006100" }}>
                        {(
                            orders.reduce((sum, order) => sum + order.amount, 0) -
                            orders.reduce((sum, order) => sum + order.amount, 0) * 0.0299
                        ).toFixed(2)}{" "}
                        ر.س
                        </span>
                    </h3>
                    </div>

                    {/* File input and transfer confirmation */}
                    <div style={{ marginTop: "64px" }}>
                    <h3>تأكيد تحويل المبلغ</h3>

                    <input
                        ref={transferPDFRef}
                        onChange={handleFileChange}
                        type='file'
                        accept='.pdf, image/*'
                        style={{
                        backgroundColor: "#F0F0F0",
                        border: "none",
                        width: window.innerWidth > window.innerHeight ? "50%" : "100%",
                        height: "40px",
                        borderRadius: "8px",
                        outline: "none",
                        padding: "8px 16px",
                        textAlign: "right",
                        marginTop: "8px",
                        display: "none"
                        }}
                    />

                    <div
                        onClick={() => {
                        if (transferPDFRef.current) {
                            transferPDFRef.current.click();
                        }
                        }}
                        style={{ marginBottom: "16px", display: "flex", alignItems: "center", justifyContent: "flex-start", cursor: "pointer", marginTop: "8px", width: "fit-content" }}
                    >
                        <GoPaperclip
                        size={32}
                        color='#00C8B8'
                        style={{ padding: "4px", borderRadius: "50%" }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = "#F7F7F7"} // Change color on hover
                        onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"}
                        />
                        <p style={{ marginBottom: "0px", fontSize: "11px", marginRight: "2px" }}>إرفاق ملف أو صورة (PNG, JPG, PDF)</p>
                    </div>

                    {fileName && (
                        <div style={{ display: "flex" }}>
                        <p>{fileName}</p>
                        <IoMdClose size={20} color='#F00057' style={{ cursor: "pointer", minHeight: "20px", minWidth: "20px", marginRight: "8px" }}
                            onClick={() => {
                            if (!confirmTransferLoading) {
                                if (transferPDFRef.current) {
                                setTransferPDF(null);
                                transferPDFRef.current.value = null;
                                setFileName("");
                                }
                            }
                            }}
                        />
                        </div>
                    )}

                    <button
                        disabled={!transferPDF || confirmTransferLoading}
                        className='button'
                        style={{ marginTop: "16px" }}
                        onClick={() => {
                            // Extract order IDs
                            const orderIds = orders.map(order => order.id);
                          
                            // Calculate the total amount
                            const totalAmount = orders.reduce((sum, order) => sum + order.amount, 0);
                          
                            // Calculate 2.99% fee
                            const feePercentage = 2.99 / 100;
                            const fee = totalAmount * feePercentage;
                          
                            // Calculate the total amount including the fee
                            const amountWithFee = totalAmount - fee;
                          
                            // Call handleTransfer with the updated amount
                            handleTransfer(orderIds, amountWithFee);
                          }}
                          
                    >

<div style={{
  display: "flex",
  flexDirection: "row", // Use column layout to stack items vertically
  alignItems: "center",
  justifyContent: "center",
}}>
  <p style={{
    marginBottom: "0px",
    whiteSpace: "nowrap",
    fontSize: "14px"
  }}>تأكيد التحويل</p>
  {confirmTransferLoading && (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      marginTop:"4px",
      marginRight:"8px"
    }}>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}>
        <Oval
          height={"15px"}
          width={"20px"}
          color={"white"}
          wrapperClass=""
          visible={true}
          wrapperStyle={{
            display: "block",
            marginTop: "2px",
            padding: 0
          }}
          ariaLabel='oval-loading'
          secondaryColor="#ccc"
          strokeWidth={1}
          strokeWidthSecondary={1}
        />
      </div>
    </div>
  )}
</div>


                    </button>
                    </div>
                </>
                ) : (
                <p>No orders found for this user.</p>
                )}
</div>


          </div>
    </>
  );
}

export default TransferToUserScreen;
