import React, { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';

const LoadingScreen = ({isLoading, isAppJs}) => {
  useEffect(() => {
    // Scroll to the top of the page when the LoadingScreen component mounts
    window.scrollTo(0, 0);
  }, []);


  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // Initial call to set the state with current window dimensions
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{
        width: window.innerWidth > window.innerHeight ? isAppJs ? "100%" : "78%" : "100%",
        marginRight: window.innerWidth > window.innerHeight ? "calc(22% + 16px)" : "0px",
        height: "100%",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: "fixed",
        top:0,
        right:0,
        left: 0,
        backgroundColor: "transparent",
        opacity: isLoading ? 1 : 0,
        visibility: isLoading ? 'visible' : 'hidden', // Control visibility
        transition: "opacity 0.3s ease, visibility 0s 0.3s", // Delay visibility change
        borderRadius: "24px",
    }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width:"fit-content", height:"fit-content", flexDirection:"column", backgroundColor:"white", marginRight:"auto", marginLeft:"auto" }}>
        <Oval
          height={"35px"}
          width={"35px"}
          color={"#00C8B8"}
          wrapperClass=""
          visible={true}
          wrapperStyle={{ display: "block" }}
          ariaLabel='oval-loading'
          secondaryColor="#ccc"
          strokeWidth={3}
          strokeWidthSecondary={2}
        />
        {/* {
          text && (
            <p>{text}</p>
          )
        } */}
      </div>
    </div>
  );
}

export default LoadingScreen;